@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100%;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
}

svg {
  vertical-align: baseline;
}
